export const fraisPort = 14.95
export const fraisPortCorse = 24.9

export const optionSerenite = 1.5
export const pourcentageExpress = 1.2
export const getRemise = (total) => (total >= 250 ? (total >= 450 ? 0.2 : 0.1) : 0)

export const bordureMin = 2
export const options_pp = [
  [1, 2],
  [1, 3],
  [1, 4],
  [2, 2],
  [1, 5],
  [1, 6],
  [2, 1],
  [3, 1],
  [4, 1],
  [5, 1],
  [6, 1],
  [2, 3],
  [3, 2],
]

export const orderPp = {
  "Passe partout : âme blanche épaisseur 1,4mm 80x120cm": 1,
  "Passe partout : âme noire épaisseur 1,4mm 80x120cm": 2,
  "Passe partout : âme blanche épaisseur 3,3mm 80x120cm": 3,
  "Passe partout : âme teintée dans la masse conservation épaisseur 1,5mm 80x120cm": 4,
  "Passe partout : âme teintée dans la masse conservation épaisseur 2,5mm 80x120cm": 5,
  "Passe partout : âme couleur pigmentée conservation épaisseur 1,7mm 80x102cm": 6,
  "Double  extra blanc Passe partout : âme blanche épaisseur 1,4mm 80x120cm": 7,
  "Double  blanc cassé Passe partout : âme blanche épaisseur 1,4mm 80x120cm": 8,
  "Double  crème Passe partout : âme blanche épaisseur 1,4mm 80x120cm": 9,
}
export const DPI = 150 / 2.54
