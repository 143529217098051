export const LOGIN = "LOGIN"
export const login = identifiant => {
  return {
    type: LOGIN,
    payload: identifiant,
  }
}

export const LOGOUT = "LOGOUT"
export const logout = () => ({
  type: LOGOUT,
})

export const SET_MODE_PAIEMENT = "SET_MODE_PAIEMENT"
export const setModePaiement = modePaiement => {
  return {
    type: SET_MODE_PAIEMENT,
    modePaiement,
  }
}

export const SHOW_LOADING = "SHOW_LOADING"
export const CLEAR_LOADING = "CLEAR_LOADING"
export const SET_LOADING_MESSAGE = "SET_LOADING_MESSAGE"
export const showLoading = () => ({
  type: SHOW_LOADING,
})

export const clearLoading = (status = null, message = "") => ({
  type: CLEAR_LOADING,
  status,
  message,
})
export const setLoadingMessage = (message = "") => ({
  type: SET_LOADING_MESSAGE,
  message,
})
