export const MJS_SERVICE = "MJS_SERVICE"
export const SET_DIMENSIONS_INVALID = "SET_DIMENSIONS_INVALID"
export const SET_DIMENSIONS_DEFAULT = "SET_DIMENSIONS_DEFAULT"
export const SET_PROTECTION = "SET_PROTECTION"
export const SET_RECOUVREMENT = "SET_RECOUVREMENT"
export const SET_ISOREL = "SET_ISOREL"
export const SET_REHAUSSE_COLOR = "SET_REHAUSSE_COLOR"
export const RESET_PAGE_PRODUCT = "RESET_PAGE_PRODUCT"
export const SET_BAGUETTE_DOUBLO = "SET_BAGUETTE_DOUBLO"
export const SET_CARTON_BARRIERE = "SET_CARTON_BARRIERE"
export const SET_KIT_MONTAGE = "SET_KIT_MONTAGE"
export const SET_DIMENSIONS = "SET_DIMENSIONS"

export const setRehausseColor = (key, color) => {
  return {
    type: SET_REHAUSSE_COLOR,
    key,
    color,
  }
}

export const resetPageProduct = () => {
  return {
    type: RESET_PAGE_PRODUCT,
  }
}

export const setBaguetteDoublo = (baguette) => {
  return {
    type: SET_BAGUETTE_DOUBLO,
    baguette,
  }
}

export const setCartonBarriere = (cartonBarriere) => {
  return {
    type: SET_CARTON_BARRIERE,
    cartonBarriere,
  }
}

export const setKitMontage = (kitMontage) => {
  return {
    type: SET_KIT_MONTAGE,
    kitMontage,
  }
}

export const setDimensions = (largeur, hauteur, resetRecouvrement = false, setFrom = null) => {
  return {
    type: SET_DIMENSIONS,
    largeur,
    hauteur,
    resetRecouvrement,
    setFrom,
  }
}

export const mjsService = (service) => {
  return {
    type: MJS_SERVICE,
    service,
  }
}

export const setDimensionsInvalid = () => {
  return {
    type: SET_DIMENSIONS_INVALID,
  }
}
export const setDimensionsDefault = () => ({
  type: SET_DIMENSIONS_DEFAULT,
})

export const clearDimensions = () => {
  return (dispatch, getState) => {
    if (getState().aspectProduit.dimensionsSetFrom === "passe") {
      dispatch(setDimensionsDefault())
    }
  }
}

/**
 *
 * @param {bool} isorel défini l'isorel du cadre
 * @param {bool} isorelSet si true, correspond à une modification de l'utilisateur (qui sera remise à zéro au changmeent)
 */
export const setIsorel = (isorel, isorelSet = false) => {
  return {
    type: SET_ISOREL,
    isorel,
    isorelSet,
  }
}

export const setProtection = (protection) => ({
  type: SET_PROTECTION,
  protection,
})

export const setRecouvrement = () => ({
  type: SET_RECOUVREMENT,
})
