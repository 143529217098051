export const SET_ASPECT_MARGES = "SET_ASPECT_MARGES"
export const SET_SHOW_EDIT_MARGES = "SET_SHOW_EDIT_MARGES"

export const setAspectMarges = status => {
  return {
    type: SET_ASPECT_MARGES,
    status,
  }
}

export const setShowEditMarges = status => {
  return {
    type: SET_SHOW_EDIT_MARGES,
    status,
  }
}
