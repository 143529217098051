export const SET_PP_MARGE_H = "SET_PP_MARGE_H"
export const SET_PP_MARGE_B = "SET_PP_MARGE_B"
export const SET_PP_MARGE_G = "SET_PP_MARGE_G"
export const SET_PP_MARGE_D = "SET_PP_MARGE_D"
export const SET_PP_INTER_H = "SET_PP_INTER_H"
export const SET_PP_INTER_L = "SET_PP_INTER_L"
export const SET_PP_OUVERTURES = "SET_PP_OUVERTURES"
export const SET_PP = "SET_PP"
export const SET_PP_TYPE = "SET_PP_TYPE"
export const SET_PP_MARGIN_VALID = "SET_PP_MARGIN_VALID"

export const setPpMargeH = marge => ({
  type: SET_PP_MARGE_H,
  marge,
})
export const setPpMargeB = marge => ({
  type: SET_PP_MARGE_B,
  marge,
})
export const setPpMargeD = marge => ({
  type: SET_PP_MARGE_D,
  marge,
})
export const setPpMargeG = marge => ({
  type: SET_PP_MARGE_G,
  marge,
})

export const setPpInterH = marge => ({
  type: SET_PP_INTER_H,
  marge,
})
export const setPpInterL = marge => ({
  type: SET_PP_INTER_L,
  marge,
})

// from permet de remettre à zéro sur la page produit
export const setPpOuvertures = (ouvertures, from = null) => ({
  type: SET_PP_OUVERTURES,
  ouvertures,
  from,
})

export const setPp = (pp, default_ = false) => ({
  type: SET_PP,
  default: default_,
  pp,
})

export const setPpType = pp_type => ({
  type: SET_PP_TYPE,
  pp_type,
})
export const setPpMarginValid = status => ({
  type: SET_PP_MARGIN_VALID,
  status,
})
