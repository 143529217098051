export const SET_SERENITE = "SET_SERENITE"
export const SET_EXPRESS = "SET_EXPRESS"
export const ADD_PRODUCT = "ADD_PRODUCT"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const REMOVE_PRODUCT = "REMOVE_PRODUCT"
export const SET_PAYPAL_ORDERID = "SET_PAYPAL_ORDERID"
export const REMOVE_ALL_PRODUCTS = "REMOVE_ALL_PRODUCTS"
export const SET_RECAP_PAIEMENT = "SET_RECAP_PAIEMENT"
export const MODE_LIVRAISON = "MODE_LIVRAISON"
export const SET_DELIVERY_ADDRESS = "SET_DELIVERY_ADDRESS"
export const SET_INVOICE_ADDRESS = "SET_INVOICE_ADDRESS"
export const SET_DELIVERY_METHOD = "SET_DELIVERY_METHOD"
export const SET_PICKUP_POINT = "SET_PICKUP_POINT"
export const CLEAR_RECAP_PAIEMENT = "CLEAR_RECAP_PAIEMENT"
export const RESET_AFTER_ADD = "RESET_AFTER_ADD"
export const SET_COMMENTAIRE = "SET_COMMENTAIRE"
import localForage from "localforage"

export const setSerenite = (serenite) => {
  return {
    type: SET_SERENITE,
    serenite,
  }
}

export const setCommentaire = (commentaire) => {
  return {
    type: SET_COMMENTAIRE,
    commentaire,
  }
}

export const setExpress = (express) => {
  return {
    type: SET_EXPRESS,
    express,
  }
}

export const addProduct = (product) => {
  return {
    type: ADD_PRODUCT,
    payload: product,
  }
}

export const updateProduct = (product) => {
  return {
    type: UPDATE_PRODUCT,
    payload: product,
  }
}

export const removeProduct = (_id) => {
  return {
    type: REMOVE_PRODUCT,
    _id,
  }
}

export const setPaypalOrderid = (data) => {
  return {
    type: SET_PAYPAL_ORDERID,
    payload: data,
  }
}

export const removeAllProducts = (data) => {
  localForage.clear()
  return {
    type: REMOVE_ALL_PRODUCTS,
    payload: data,
  }
}
export const setRecapPaiement = (data) => {
  return {
    type: SET_RECAP_PAIEMENT,
    payload: data,
  }
}

export const setModeLivraison = (data) => {
  return {
    type: MODE_LIVRAISON,
    payload: data,
  }
}

export const setDeliveryAddress = (id_address, zipcode) => {
  return {
    type: SET_DELIVERY_ADDRESS,
    id_address,
    zipcode,
  }
}

export const setInvoiceAddress = (id_address) => {
  return {
    type: SET_INVOICE_ADDRESS,
    id_address,
  }
}

export const setDeliveryMethod = (id_method, fraisPort) => {
  return {
    type: SET_DELIVERY_METHOD,
    id_method,
    fraisPort,
  }
}
export const setPickupPoint = (id_point) => {
  return {
    type: SET_PICKUP_POINT,
    id_point,
  }
}
export const clearRecapPaiement = () => {
  return {
    type: CLEAR_RECAP_PAIEMENT,
  }
}
export const resetAfterAdd = () => {
  return {
    type: RESET_AFTER_ADD,
  }
}
