export { default as wrapRootElement } from './wrap-with-provider';

export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
      `Le site a été mis à jour, ` +
        `Voulez vous recharger la page pour afficher la dernière version ?`
    )
    if (answer === true) {
      window.location.reload()
    }
  }