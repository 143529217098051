/* eslint-disable no-case-declarations */
import { combineReducers } from "redux"

import { SET_PREVISU_PHOTO, PHOTO_ADD, SET_TIRAGE, PHOTO_REMOVE, PHOTO_REPLACE, PHOTO_CANCEL, RATIO_SET, PHOTO_DIMENSION_MAX } from "../actions/photos"
import {
  SET_PP_OUVERTURES,
  SET_PP_MARGE_B,
  SET_PP_MARGE_D,
  SET_PP_MARGE_G,
  SET_PP_MARGE_H,
  SET_PP_TYPE,
  SET_PP,
  SET_PP_INTER_H,
  SET_PP_INTER_L,
  SET_PP_MARGIN_VALID,
} from "../actions/passe"
import {
  SET_PROTECTION,
  SET_ISOREL,
  SET_DIMENSIONS_INVALID,
  SET_DIMENSIONS_DEFAULT,
  SET_DIMENSIONS,
  SET_KIT_MONTAGE,
  SET_CARTON_BARRIERE,
  MJS_SERVICE,
  SET_BAGUETTE_DOUBLO,
  SET_REHAUSSE_COLOR,
  RESET_PAGE_PRODUCT,
  SET_RECOUVREMENT,
} from "../actions/cadre"
import { CLEAR_LOADING, SET_LOADING_MESSAGE, SHOW_LOADING, LOGOUT, LOGIN, SET_MODE_PAIEMENT } from "../actions/"
import { fraisPort, fraisPortCorse } from "../services/prixBaguettes"
import {
  SET_EXPRESS,
  SET_SERENITE,
  REMOVE_PRODUCT,
  SET_DELIVERY_ADDRESS,
  SET_INVOICE_ADDRESS,
  SET_DELIVERY_METHOD,
  SET_PICKUP_POINT,
  REMOVE_ALL_PRODUCTS,
  SET_RECAP_PAIEMENT,
  SET_PAYPAL_ORDERID,
  UPDATE_PRODUCT,
  ADD_PRODUCT,
  CLEAR_RECAP_PAIEMENT,
  RESET_AFTER_ADD,
  SET_COMMENTAIRE,
} from "../actions/panier"
import deepEqual from "deep-equal"
import { SET_ASPECT_MARGES, SET_SHOW_EDIT_MARGES } from "../actions/aspect"

const calcPort = (products, deliveryZipcode, modeLivraison, pickupPoint) => {

  return modeLivraison === 10 ? 0 : (modeLivraison === 9 || (products.length !== 0 && products.every((e) => e.echantillon)) ?
    0 :
    (modeLivraison === 16 ? 
      (pickupPoint?.postal_code?.slice(0, 2) !== "20" ? fraisPort : fraisPortCorse) :
      (deliveryZipcode?.slice(0, 2) !== "20" ? fraisPort : fraisPortCorse)))
}

const panierR = (
  state = {
    products: [],
    recapPaiement: {},
    fraisPort: fraisPort,
    deliveryAddress: null,
    deliveryZipcode: null,
    pickupPoint: null,
    invoiceAddress: null,
    modeLivraison: 12,
    paypalOrderId: null,
    serenite: false,
    express: false,
    modePaiement: null,
    commentaire: "",
  },
  action
) => {
  switch (action.type) {
    case SET_COMMENTAIRE:
      return { ...state, commentaire: action.commentaire }
    case CLEAR_RECAP_PAIEMENT:
      return { ...state, recapPaiement: {} }
    case SET_MODE_PAIEMENT:
      return { ...state, modePaiement: action.modePaiement }
    case SET_SERENITE:
      return { ...state, serenite: action.serenite }
    case SET_EXPRESS:
      return { ...state, express: action.express }
    case ADD_PRODUCT:
      const newProduct = {
        ...action.payload,
        position: state.products.length + 1,
      }
      const newProducts = [...state.products]
      newProducts.unshift(newProduct)

      return {
        ...state,
        products: newProducts,
        modePaiement: null,
        modeLivraison: 12,
        pickupPoint: null,
        fraisPort: calcPort(newProducts, state.deliveryZipcode, 12, null),
      }

    case SET_DELIVERY_ADDRESS:
      return { ...state, deliveryAddress: action.id_address, deliveryZipcode: action.zipcode,
        fraisPort: calcPort(state.products, action.zipcode, state.modeLivraison, state.pickupPoint) }
    case SET_INVOICE_ADDRESS:
      return { ...state, invoiceAddress: action.id_address }
    case UPDATE_PRODUCT: {
      const productrmvd = state.products.find((product) => product._id == action.payload._id)
      const newProductUpd = {
        ...productrmvd,
        qty: action.payload.qty + productrmvd.qty,
        ...action.payload,
      }
      const newProductsUpd = state.products.filter((product) => product._id !== action.payload._id)
      newProductsUpd.unshift(newProductUpd)
      return {
        ...state,
        products: newProductsUpd,
        modePaiement: null,
        modeLivraison: 12,
        pickupPoint: null,
        fraisPort: calcPort(newProductsUpd, state.deliveryZipcode, 12, null),
      }
    }
    case SET_PAYPAL_ORDERID: {
      return {
        ...state,
        paypalOrderId: action.payload,
      }
    }
    case REMOVE_PRODUCT:
      const newProductsRm = [...state.products].filter((p) => p._id !== action._id)
      return {
        ...state,
        products: newProductsRm,
        modePaiement: null,
        modeLivraison: 12,
        pickupPoint: null,
        fraisPort: calcPort(newProductsRm, state.deliveryZipcode, 12, null),
      }
    case SET_RECAP_PAIEMENT: {
      return {
        ...state,
        recapPaiement: action.payload,
      }
    }
    case REMOVE_ALL_PRODUCTS: {
      return {
        ...state,
        products: [],
        recapPaiement: action.payload,
        paypalOrderId: null,
        idOrder: null,
        serenite: false,
        express: false,
        deliveryAddress: null,
        deliveryZipcode: null,
        invoiceAddress: null,
        fraisPort: fraisPort,
        modeLivraison: 12,
        pickupPoint: null,
        commentaire: "",
      }
    }
    case LOGOUT:
      return { ...state, deliveryAddress: null, deliveryZipcode: null, invoiceAddress: null, modeLivraison: 12, pickupPoint: null, fraisPort: fraisPort }
    case SET_PICKUP_POINT:
      return { ...state, pickupPoint: action.id_point, fraisPort: calcPort(state.products, state.deliveryZipcode, state.modeLivraison, action.id_point) }
    case SET_DELIVERY_METHOD:
      return {
        ...state,
        modeLivraison: action.id_method,
        pickupPoint: action.id_method !== 16 ? null : state.pickupPoint,
        fraisPort: calcPort(state.products, state.deliveryZipcode, action.id_method, action.id_method !== 16 ? null : state.pickupPoint),
      }
  }
  return state
}

const LoginR = (state = { identifiants: null, lastUpdate: 0 }, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        identifiants: action.payload,
        lastUpdate: Math.round(new Date() / 1000),
      }
    case LOGOUT:
      return { ...state, identifiants: null }
  }
  return state
}

/**
 * status : null pas de réponse
 * true : succès
 * false: échec
 */
const loadingR = (state = { loading: false, status: null, message: "" }, action) => {
  switch (action.type) {
    case SHOW_LOADING:
      return { ...state, loading: true, status: null, message: "" }
    case SET_LOADING_MESSAGE:
      return { ...state, message: action.message }
    case CLEAR_LOADING:
      return { ...state, loading: false, status: action.status, message: action.message }
  }
  return state
}

/** ne placer ici que les informations non-utiles a la réalisation d'un produit */
const aspectProduit = (state = { marges: false, ppMarginValid: false, dimensionsSetFrom: null, showEditMarges: false }, action) => {
  switch (action.type) {
    case SET_DIMENSIONS:
      return { ...state, dimensionsSetFrom: action.setFrom, showEditMarges: action.setFrom === "home" }
    case SET_DIMENSIONS_DEFAULT:
      return { ...state, dimensionsSetFrom: null }
    case RESET_PAGE_PRODUCT:
      return { ...state, marges: false }
    case SET_PP_MARGIN_VALID:
      return { ...state, ppMarginValid: action.status }
    case SET_PP_TYPE:
      return { ...state, ppMarginValid: true, showEditMarges: ["simple", "multivues"].includes(action.pp_type) }
    case SET_ISOREL:
      return { ...state, marges: action.isorel === false ? false : state.marges }
    case SET_ASPECT_MARGES:
      return { ...state, marges: action.status, showEditMarges: false }
    case SET_SHOW_EDIT_MARGES:
      return { ...state, showEditMarges: action.status }
    default:
      return state
  }
}

/**
 * Ce reducer contient les informations nécéssaires à la réalisation du produit (non pas l'affichage sur le site)
 */
const pp = { type: null, pp: null, margeH: 0, margeB: 0, margeD: 0, margeG: 0, interL: 0, interH: 0, ouvertures: [1, 1], default: true }
const initial = {
  rehausse: null,
  rehausseColor: null,
  doublo: null,
  service: 'coupe_assemble_option',
  kitMontage: null,
  cartonBarriere: null,
  dimensionsValid: false,
  dimensionsDefault: true,
  largeur: 40,
  hauteur: 50,
  isorel: null,
  isorelSet: null, // Permet de savoir si c'est l'utilisateur qui à configuré ou si c'est automatique
  pp,
  protection: null,
  recouvrement: false,
}
const PageProduit = (state = initial, action) => {
  switch (action.type) {
    case RESET_AFTER_ADD:
      return { ...state, ...initial, doublo: state.doublo, largeur: 40, hauteur: 50, dimensionsDefault: true, dimensionsValid: true }
    case RESET_PAGE_PRODUCT:
      return {
        ...state,
        doublo: null,
        rehausse: null,
        rehausseColor: null,
        protection: null,
        kitMontage: null,
        cartonBarriere: null,
        isorel: state.isorelSet === "pp" ? state.isorel : null,
        isorelSet: null,
        pp: { ...state.pp, default: true, type: state.pp.setFrom === "passe" ? null : state.pp.type, setFrom: null },
      }
    case SET_REHAUSSE_COLOR:
      return { ...state, rehausseColor: action.color, rehausse: action.key }
    case SET_BAGUETTE_DOUBLO:
      return { ...state, doublo: action.baguette }

    case MJS_SERVICE: {
      let cartonBarriere = state.cartonBarriere
      let kitMontage = state.kitMontage
      if (action.service == "precoupe") cartonBarriere = false
      if (action.service !== "precoupe") kitMontage = false
      return {
        ...state,
        service: action.service,
        kitMontage,
        cartonBarriere,
        isorel: action.service !== "coupe_assemble_option" && action.service !== null ? false : state.isorel,
        pp: action.service !== "coupe_assemble_option" ? { ...pp, type: "aucun" } : state.pp,
      }
    }
    case SET_CARTON_BARRIERE:
      return { ...state, cartonBarriere: action.cartonBarriere }
    case SET_KIT_MONTAGE:
      return { ...state, kitMontage: action.kitMontage, protection: "aucune" }
    case SET_DIMENSIONS:
      return {
        ...state,
        largeur: action.largeur,
        hauteur: action.hauteur,
        dimensionsValid: true,
        dimensionsDefault: false,
        recouvrement: action.resetRecouvrement ? false : state.recouvrement,
        pp: { ...state.pp, setFrom: action.setFrom || state.pp?.setFrom || null },
      }
    case SET_RECOUVREMENT:
      if (!state.recouvrement) {
        return {
          ...state,
          largeur: parseFloat(parseFloat(state.largeur - 0.5).toFixed(2)),
          hauteur: parseFloat(parseFloat(state.hauteur - 0.5).toFixed(2)),
          recouvrement: true,
        }
      } else return state
    case SET_DIMENSIONS_DEFAULT:
      return { ...state, largeur: 40, hauteur: 50, dimensionsDefault: true, dimensionsValid: true, recouvrement: true }
    case SET_DIMENSIONS_INVALID:
      return { ...state, dimensionsValid: false }
    case SET_PP:
      return { ...state, pp: { ...state.pp, pp: action.pp, default: action.default } }
    case SET_PP_TYPE:
      return {
        ...state,
        pp: {
          ...state.pp,
          type: action.pp_type,
          ouvertures: [null, "simple", "aucun"].includes(action.pp_type) ? [1, 1] : state.pp.ouvertures,
          margeH: action.pp_type == "aucun" ? 0 : 5,
          margeB: action.pp_type == "aucun" ? 0 : 5,
          margeG: action.pp_type == "aucun" ? 0 : 5,
          margeD: action.pp_type == "aucun" ? 0 : 5,
          interL: action.pp_type == "aucun" ? 0 : 5,
          interH: action.pp_type == "aucun" ? 0 : 5,
        },
        isorel: ["simple", "multivues"].includes(action.pp_type) ? true : state.isorel,
        isorelSet: ["simple", "multivues"].includes(action.pp_type) ? "pp" : state.isorel,
      }

    case SET_ISOREL:
      return { ...state, isorel: action.isorel, protection: action.isorel === false ? "aucune" : state.protection, isorelSet: action.isorelSet }
    case SET_PROTECTION:
      return { ...state, protection: action.protection }
    case SET_PP_MARGE_H:
      return { ...state, pp: { ...state.pp, margeH: action.marge } }
    case SET_PP_MARGE_G:
      return { ...state, pp: { ...state.pp, margeG: action.marge } }
    case SET_PP_MARGE_D:
      return { ...state, pp: { ...state.pp, margeD: action.marge } }
    case SET_PP_MARGE_B:
      return { ...state, pp: { ...state.pp, margeB: action.marge } }
    case SET_PP_INTER_H:
      return { ...state, pp: { ...state.pp, interH: action.marge } }
    case SET_PP_INTER_L:
      return { ...state, pp: { ...state.pp, interL: action.marge } }
    case SET_PP_OUVERTURES:
      return {
        ...state,
        pp: {
          ...state.pp,
          setFrom: action.from,
          ouvertures: action.ouvertures,
          type: action.from === "tirage" ? null : deepEqual([1, 1], action.ouvertures) ? "simple" : "multivues",
          margeH: 5,
          margeB: 5,
          margeG: 5,
          margeD: 5,
          interL: 5,
          interH: 5,
        },
      }
    case SET_TIRAGE:
      return { ...state, isorel: action.tirage === "tirage_sublimation" ? true : state.isorel }
    case PHOTO_CANCEL:
      return {
        ...state,
        pp: { ...state.pp, type: null, ouvertures: [1, 1] },
        largeur: 40,
        hauteur: 50,
        dimensionsDefault: true,
        dimensionsValid: true,
        recouvrement: true,
      }
    default:
      return state
  }
}

const photosR = (
  state = {
    photos: {},
    tirage: null,
    ratio: 16 / 9,
    previsuPhoto: null,
    largeurMax: null,
    hauteurMax: null,
  },
  action
) => {
  switch (action.type) {
    case PHOTO_CANCEL:
      return { ...state, photos: {}, tirage: null, ratio: 16 / 9, largeurMax: null, hauteurMax: null, previsuPhoto: null }
    case RATIO_SET:
      return { ...state, ratio: action.ratio }
    case SET_PREVISU_PHOTO:
      return { ...state, previsuPhoto: action.photo }
    case PHOTO_ADD:
      return { ...state, photos: { ...state.photos, [action.position]: action.photo }, previsuPhoto: null }
    case PHOTO_REMOVE:
      // eslint-disable-next-line no-unused-vars
      const { [action.index]: _, ...rest } = state.photos
      return { ...state, photos: rest }
    case PHOTO_REPLACE:
      return { ...state, photos: { ...state.photos, [action.index]: action.photo } }
    case SET_TIRAGE:
      return { ...state, tirage: action.tirage }
    case PHOTO_DIMENSION_MAX:
      return { ...state, hauteurMax: action.hauteur, largeurMax: action.largeur }
    default:
      return state
  }
}

const allReducers = combineReducers({
  panierR,
  identifiants: LoginR,
  loadingR,
  aspectProduit,
  PageProduit,
  photosR,
})

export default allReducers
