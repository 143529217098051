// Prévisu :
export const SET_PREVISU_PHOTO = "SET_PREVISU_PHOTO"
export const setPrevisuPhoto = photo => ({
  type: SET_PREVISU_PHOTO,
  photo,
})

// Tirage :
export const PHOTO_ADD = "PHOTO_ADD"
export const RATIO_SET = "RATIO_SET"
export const PHOTO_CANCEL = "PHOTO_CANCEL"
export const PHOTO_REMOVE = "PHOTO_REMOVE"
export const PHOTO_REPLACE = "PHOTO_REPLACE"
export const SET_TIRAGE = "SET_TIRAGE"
export const PHOTO_DIMENSION_MAX = "PHOTO_DIMENSION_MAX"

export const addPhoto = (position, photo) => ({
  type: PHOTO_ADD,
  position,
  photo,
})

export const removePhoto = index => ({
  type: PHOTO_REMOVE,
  index,
})

export const replacePhoto = (index, photo) => ({
  type: PHOTO_REPLACE,
  index,
  photo,
})

export const setTirage = tirage => ({
  type: SET_TIRAGE,
  tirage,
})

export const setRatio = ratio => ({
  type: RATIO_SET,
  ratio,
})
export const cancelPhoto = () => ({
  type: PHOTO_CANCEL,
})
export const setDimensionMax = (largeur, hauteur) => ({
  type: PHOTO_DIMENSION_MAX,
  largeur,
  hauteur,
})
